.blog-post {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 100px;
    line-height: 1.6; /* Improve line spacing */
}

.card {
    border: none;
}

.credit-card-container {
    display: flex;
    justify-content: space-around; /* Adjust as needed */
}

.credit-card {
    margin: 0 10px; /* Adjust spacing between cards */
}

.italic-text {
    font-style: italic;
}

.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.backpack-image {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    border-radius: 10px; /* Optional: Add rounded corners */
    width: 60%; /* Shrink the image further */
}

/* Ensure better spacing between paragraphs */
.blog-post p {
    margin-bottom: 20px;
}

.youtube-video {
    text-align: center;
    margin-bottom: 20px;
}

.center-bullet-points {
    text-align: center;
}
