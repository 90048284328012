/* ContactForm.css */

.contact-us-container {
    background-color: #2B2A30; /* Background color */
    color: #7CBFC8; /* Text color */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 10px 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow for raised effect */
    padding: 30px; /* Add some padding */
    width: 80%; /* Set width */
    max-width: 75vw; /* Maximum width */
    margin: 5% auto; /* Center the container horizontally */
}

.form-group {
    margin-bottom: 20px; /* Add some space between form groups */
}

.text-muted {
    color: #7CBFC8; /* Text color for muted text */
}

.btn-primary {
    margin-top: 20px; /* Adjust button margin */
    background-color: #7CBFC8; /* Background color for submit button */
    border-color: #7CBFC8; /* Border color for submit button */
}

.btn-primary:hover {
    background-color: #EA8686; /* Background color for submit button on hover */
    border-color: #EA8686; /* Border color for submit button on hover */
    color: #fff; /* Text color for submit button on hover */
}

.disclaimer {
    color: #7CBFC8; /* Text color */
    font-style: italic; /* Italicize the text */
    margin-top: 10px; /* Add some space between the form and the disclaimer */
}

/* ContactForm.css */

.form-group.name-group {
    margin-bottom: 20px !important; /* Add some space between form groups */
}
