@import '/node_modules/bootstrap/dist/css/bootstrap.min.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #7CBFC8;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 360px; /* Adjust as needed */
  margin: 10px;
}

.embedded-script-container {
  padding: 5% 10%; /* Adjust padding as needed */
}

body {
  background-color: #F5F5F5;
}