.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #2B2A30;
    color: #7CBFC8;
    padding: 20px 0;
    text-align: center;
    z-index: 1000; /* Ensure it's above other content */
}

.footer .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* Limit height to allow scrolling */
    max-height: 200px; /* Adjust according to your content */
    /* Enable vertical scrollbar */
    overflow-y: auto;
}

.footer .footer-content > * {
    flex: 1;
}

.footer .social-links a:hover {
    color: #EA8686;
}

.social-links {
    display: flex;
    align-items: center;
}

.social-links a {
    max-height: 40px;
    font-size: 24px;
    color: #7CBFC8;
    margin-left: 10px; /* Add spacing between social icons */
}

.coffee-link img {
    max-height: 40px; /* Limit the height of the Buy Me a Coffee image */
}

.social-links-container {
    display: flex;
    justify-content: center; /* Center the social links horizontally */
    align-items: center; /* Center the social links vertically */
}
