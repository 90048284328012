/* NavigationBar.css */
.navbar-container {
    background: #2B2A30;
    color: #7CBFC8;
    margin: 0;
    border-radius: 0;
}

.navbar-toggler {
    border: none;
}

.navbar-nav .nav-link,
.dropdown-item,
.nav-dropdown,
.nav-dropdown .dropdown-item {
    transition: color 0.2s;
    color: #7CBFC8;
    font-family: 'Bebas Neue', sans-serif;
    font-weight: bold;
    font-size: 18px;
    margin-right: 15px;
}

.navbar-nav .nav-link:hover,
.dropdown-item:hover,
.nav-dropdown:hover,
.nav-dropdown .dropdown-item:hover {
    color: #EA8686;
    text-decoration: underline;
}

.navbar-nav .nav-link:focus,
.dropdown-item:focus,
.nav-dropdown:focus,
.nav-dropdown .dropdown-item:focus {
    color: #EA8686 !important;
}

.logo {
    height: 125px;
    width: 125px;
    position: absolute;
    left: 10px;
    top: 50%; /* Vertically center the logo */
    transform: translateY(-50%);
    z-index: 1;
}

.white-space-padding {
    width: 100%;
    height: 80px;
}

@media (min-width: 768px) {
    .navbar-nav.ml-auto {
        margin-left: auto;
        text-align: center;
    }

    .nav-link,
    .dropdown-item,
    .nav-dropdown,
    .nav-dropdown .dropdown-item {
        text-align: center;
    }

    .dropdown-menu {
        background-color: #2B2A30 !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}
