.flex-container {
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    height: 100vh; /* Set the height to fill the viewport */
}

.centered-image {
    max-width: 100%; /* Ensure the image doesn't exceed its container */
    max-height: 75vh; /* Limit the image height */
    object-fit: contain; /* Maintain aspect ratio while fitting inside the container */
}

.counters-container {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
    text-align: center;
}

.counter h2 {
    margin-bottom: 10px;
}