.wrapper {
    display: flex;
    justify-content: space-around;
    gap: 15px;
}

.counter-container {
    width: 28vmin;
    height: 28vmin;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 1.5em 0;
    position: relative;
    background-color: #ffffff;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    text-align: center;
    transition: transform 0.3s ease-in-out;
}

.counter-container:hover {
    transform: scale(1.05);
}

span.num {
    color: #EA8686;
    font-family: 'Bebas Neue', sans-serif;
    font-weight: 750;
    font-size: 2.5em;
}

span.text {
    color: #7CBFC8;
    font-size: 1.5em;
    padding: 0.7em 0;
    font-weight: 800;
    text-transform: uppercase;
}

.icon {
    color: #7CBFC8;
    font-size: 2.5em;
    text-align: center;
}